.grid {
  margin-top: 4rem;
  /* margin-bottom: -10%; */
  display: grid;
  grid-template-columns: repeat(3, 300px);
  grid-template-rows: repeat(2, 300px);
  padding: 20px;
  grid-gap: 80px 60px;
  justify-content: center;
}

.grid-item {
  padding: 10px;
}
img {
  width: 100%;
}

.codeto-culture-title {
  margin-top: 8rem;
  color: var(--white-white-100, #fff);
  text-align: center;
  text-shadow: 0px 0px 20px #fff;
  font-family: Baro;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
  text-transform: capitalize;
}


.grid-item {
   background-image: url('../../assets/frame-1.png');
   background-size: cover;
   padding: 1rem;;
}


@media screen and (max-width : 600px) {
   .grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      width: 100%;
   }
   
   .codeto-culture-title {
      font-size: 2rem;
      margin-top: 3rem;
   }
}


@media screen and  (max-width:936px) {
  .grid {
     display: grid;
     grid-template-columns: 2fr;
     grid-template-rows: 2fr;
     justify-content: center;
     align-items: center;
     overflow: hidden;
     width: 70%;
     margin: auto;
  }
  
  .codeto-culture-title {
     font-size: 2rem;
     margin-top: 3rem;
  }
}

@media screen and (max-width : 500px) {
   .grid {
     width: 90%;
   }
}