#aboutus {
    margin-top: 3rem;
}

.about-us-title {
    margin-bottom: 2%;
    color: var(--white-white-100, #FFF);
    text-align: center;
    text-shadow: 0px 0px 20px #FFF;
    font-family: Baro;
    font-size: 50px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.2px;
    text-decoration-line: underline;
    text-transform: capitalize;
}


.codeto-box-section {
    height: 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;
    width: 80%;
    margin: auto;
}

.about-us-box {
    flex: .6;
    height: 25rem;
    background: url('../../assets/rect.png');
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    display: grid;
    place-items: center;
    font-family: 'Baro';
}

.about-codeto {
    width: 90%;
    line-height: 2.2rem;
}

.codeto-image-bg {
    flex: .3;
    height: 25rem;
    background-image: url(../../assets/rect.png);
    border-radius: 10px;
    display: grid;
    place-items: center;
}


.codeto-image-image {
    background: #000;
    height: 21rem;
    width: 20rem;
    border-radius: 10px;
    display: grid;
    place-items: center;
}


@media screen and (max-width : 1280px) {
    .about-us-box {
        width: 50% !important;
        border-radius: 10px;
    }

    .codeto-image-bg {
        width: 30% !important;
    }

}



@media screen and (max-width:936px) {

    .codeto-box-section {
        flex-direction: column;
    }

    .about-us-title {
        margin-top: 3rem;
        padding: 2rem;
    }

    .codeto-image-bg {
        padding: 1rem;
    }

    .codeto-image-image {
        width: 25rem;
    }

    .about-us-box {
        width: 90% !important;
        margin-top: 3rem;
        height: 100%;
        background-size: cover;
        display: flex;
        justify-content: flex-start;
    }

    .about-us-box,
    .codeto-image-bg {
        width: 80% !important;
    }

    .about-us-box {
        padding: 1rem;
    }

    .codeto-image-image {
        width: 100%;
    }

    .image-box {
        width: 70% !important;
         height: 100%;
    }
}

@media screen and (max-width: 425px) {
    .about-us-box {
        width: 80%;
        /* margin-top: 3rem; */
        font-size: 1rem !important;
        height: auto;
        background-size: cover;
        display: flex;
        justify-content: flex-start;
    }

    .codeto-image-bg {
        padding: 1rem;
    }

    .about-us-box,
    .codeto-image-bg {
        width: 100% !important;
    }

    .codeto-image-image {
        width: 100%;
    }



    .about-codeto {
        font-size: .7rem;
        background-size: cover;
        line-height: 1.5;
        text-align: center;
        width: 100%;
        padding: 1rem;
        color: rgb(212, 207, 207);
    }

    .codeto-box-section {
        width: 100%;
        flex-direction: column;
        /* margin-top: -8rem; */
    }



    .about-codeto-box .about {
        font-size: .9rem;
    }

    .about-codeto {
        line-height: 2;
        text-align: left;
        
    }

    .image-box {
        width: 90% !important;
        height: 20rem !important;
    }

    #aboutus {
        padding: 2rem 0;
        margin-top: 2rem;
    }

    .about-us-title {
        margin-top: -2rem;
    }

}












