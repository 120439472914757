
.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
  color: aliceblue;
}

.h-custom {
  height: calc(100% - 73px);
  color: #eee;
  

}

.adminform {
  background: rgba(34, 34, 34, 0.148);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 100%;
  height: auto;
  padding: 2rem;
}

label{
  
  font-size: .9rem;
}

#admin-btn {
  margin: 2rem 0;
  background-color: var(--g-1, linear-gradient(90deg, #E92EFB 0%, #FF2079 100%));

 
}
@media (max-width: 450px) {
  .h-custom {
    height: 70%;
  }
}

