* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

}

body {
  font-family: Baro;
  color: aliceblue;
  background-image: url('../src/assets/bg\ \(1\).webp');
  /* background-attachment: fixed; */
  background-size:cover;
  background-position: bottom;
  background-repeat: no-repeat;
  height: 100%;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




/* Home ad banner */

.banner-ad {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  text-align: center; /* Center-align text */
  
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Add shadow */
  font-family: Arial, sans-serif; /* Use a sans-serif font */
  font-size: 18px; /* Set font size */
  animation: pulse 2s infinite; /* Add pulsating animation */
}

/* Define pulsating animation */
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(.96); }
  100% { transform: scale(1); }
}

