.criteria-title {
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.25);
  font-family: Baro;
  font-size: 50px;
  font-style: normal;
  font-weight: 800;
  line-height: 60px; /* 120% */
  letter-spacing: 0.153px;
  padding-bottom: 5rem;
  line-height: 5rem;
}

.list ul li {
  color: #fff;
  font-family: Baro;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.153px;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2.2rem;
  
}

.list {
  width: 60%;
  margin: auto;
  
}

.check {
   display: flex;
   gap: 3rem;
   justify-content: center;
}


.checkbox {
  width: 3rem;
  height: 3rem;
  
}

.sub-criteria-title {
  margin-top: 4rem;
  color: #fff;
  text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.5);
  font-family: Baro;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 37.468px; /* 124.894% */
  letter-spacing: 0.153px;
  text-transform: capitalize;
}

.sure {
  width: 3rem;
  height: 3rem;
}

.end-line {
  color: #fff;
  text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.5);
  font-family: Baro;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 37.468px; /* 156.118% */
  letter-spacing: 0.153px;
  text-transform: capitalize;
  text-align: center;
  padding-top: 5rem;
}


@media screen and (max-width : 1280px) {
   .list {
     width: 80%;
   }
}

@media screen and (max-width: 820px) {
  .criteria-title {
    font-size: 2rem;
    padding: 2rem;
    line-height: 3rem;
    width: 100%;
  }

  .sub-criteria-title {
    font-size: 1rem;
    color: rgb(207, 207, 207);
    line-height: 1.5;
  }

  .list{
    width: 90%;
    height: auto;
  }

  .list ul li{
    margin: .6rem 0;
    font-size: 1rem;
  }

  .list li p{
    margin-bottom: 0;
  }
 
  .checkbox{
    scale: .6;
  }

  .sure{
    scale: .7;
  }

  .end-line{
    font-size: .8rem;
    margin: 0 1rem;
    line-height: 1.4;

  }
  
  
}

@media screen and (max-width: 500px) {
  .list {
     width: 100%;
  }
  .list ul li{
    margin: .6rem 0;
    font-size: .7rem;
  }

  .check {
     gap: 0rem;
  }

  .checkbox{
    scale: .5;
  }

  .sure{
    scale: .5;
  }
}
