
:root {
  --primary: #6a59ff;
  --white: #ffffff;
  --bg: #f5f5f5;
}

.container {
  /* max-width: 124rem; */
  /* padding: 4rem 1rem; */
  /* margin: 0 auto; */
  width: 65%;
  margin: auto;
  overflow: visible;
  /* height: auto; */
  
}

.heading {
  padding: 1rem 0;
  font-size: 3.5rem;
  text-align: center;
}

.swiper {
   width: 90% !important;
   height: 38rem !important;
}


.swiper-pagination {
  height: 15%;
  transform: translateY(200px);
}




.swiper-slide img {
  /* width: 0; */
  /* height: 10rem; */
  width: 10rem;
  /* border-radius: 2rem; */
  /* object-fit: contain; */
  
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
 
}




/* @media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
} */
/* 
@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
} */

.slider-controler .slider-arrow {
  background: #ffffffc4;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(255, 255, 255, 0.1));
}



.slider-controler .slider-arrow ion-icon {
  font-size: 1rem;
  color: #000000;
}

.slider-controler .slider-arrow::after {
  content: '';
}

.swiper-pagination {
  position: relative;
  width: 100% !important;
  /* bottom: 2rem; */
  height: 33.5rem;
  z-index: -2 !important;
}



.swiper-pagination .swiper-pagination-bullet {

  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  background-color: #fff;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
}

.box {
  width: 100%;
  height: 22.5rem;
  /* background-color: #fff; */
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 0px 10px #26262678);
}

.ach-box{
  border-radius: 2rem !important;
  border-radius: 21.006px;
background: #FFF;
box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.50);
}

.box-img{
  transform: scale(.6);
}



@media screen and (max-width : 1280px){
    .container {
       width: 100%;
       height: auto;
    }
}

@media screen and (max-width: 820px) {
  .container {
    width: 100%;
  }

  .swiper-button-prev {
    left : 2% !important;
  }

  .slider-controler .swiper-button-next {
    left : 95% !important;
  }
}

@media screen and (max-width: 768px) {
   

   .swiper-button-prev {
     left : 2% !important;
   }

  .slider-controler .swiper-button-next {
     left : 90% !important;
  }

  .swiper_container  {
    height:33rem;
  }

}


@media screen and (max-width : 425px) {
   .container {
     /* height: auto; */
     width: 100%;
   }

 
   .swiper-button-prev {
    left : 2% !important;
    background-color: #0000002a;
  }

 .slider-controler .swiper-button-next {
    /* left : 95% !important; */
    background-color: #0000002a;
 }
}
