.circlee{
    width: 10rem;
    height: 10rem;
    border: 12.758855819702148px solid #FFBE00;
    border-radius: 50%;
    position: relative;
    margin-top: 3.5rem;
}



.circlee .imag {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.circlee img {
     width: 60%;
}

.line {
  width: 2px;
  height: 100px;
  background-color:  #533E3E;
  position: absolute;
  top: -80%;
  left: 50%;
}

.line::before {
    content: "";
    width: 25px;
    height: 25px;
    background-color:  #533E3E;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 0%;
    transform: translate(-50%, -50%);
}

@media screen and (max-width : 1280px) {
    .line {
      height: 80px;
    }

    .line::before {
         top: 5%;
    }
 }
 