.highlight {
   padding: 5rem 2rem;
}


.highlight_title {
  margin-top: 8rem;
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 20px #fff;
  font-family: Baro;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
  text-transform: uppercase;
  width: 80%;
  margin: auto;
}


.diagram {
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  height: 20%;
  width: 80%;
  margin: auto;
  border-radius: 11px;
  border: 3px solid #fff;
  position: relative;
}

.horizontal-line {
  display: flex;
  width: 90%;
  height: 0;
  margin: auto;
  border: 2px solid white;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 50%;
  left: 29rem;
}


.vertical-line {
  height: 23rem;
  background-color: #fff;
  width: 6px;
  margin: 1rem auto;
  border-radius: 10px;
  position: absolute;
}

.horizontal-line {
  height: 6px;
  background-color: #fff;
  width: 97%;
  margin-right: 10rem;
  display: flex;
  border-radius: 10px white;
  position: absolute;
  left: 1%;
}

.circle {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
}

.vertical-line .circle:nth-child(1) {
  top: 0;
  right: -100%;
}

.vertical-line .circle:nth-child(2) {
  bottom: 0;
  right: -100%;
}

.vertical-line .circle:nth-child(3) {
  bottom: 0;
  right: -80%;
}

.circle {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
}

.circle2 {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
}


.horizontal-line .circle2:nth-child(1) {
  top: -10px;
  left: 100%;
}


.horizontal-line .circle2:nth-child(2) {
  top: -10px;
  left: -1%;
}


.highlights-items {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  /* align-items: center; */
  /* justify-content: center; */
  /* padding: auto; */
  position: relative;
  /* grid-gap: 6rem 4rem; */
  /* justify-content: center; */
  height: 25rem;
}


.item1 {
  display: flex;
  color: #fff;
  font-family: Baro;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 2rem; 
  gap: 1rem;
}


.item1 > .img {
  width: 50px;
  height: 50px;
}

.item1 > .img > img {
   width: 100%;
   /* margin-left: 14px; */
}

.item1 p {
   margin: 0;
   font-size: 10px !important;
}


@media screen and (max-width: 1024px) {
  .diagram {
    width: 100%;
  }
}



@media screen and (max-width: 600px) {

  .highlight {
    width: 100%;
  }

  .diagram {
    width: 100%;
    border: none;
  }

  .highlights-items {
    display: block;
    width: 100%;
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    align-items: center;
    justify-content: center;
    grid-gap: 1rem 1rem;
    justify-content: center;
    height: 25rem;
    width: 100%; */
  }

  .item1 {
    width: 100;
    justify-content: center;
    background: rgb(29 28 69 / 21%);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    margin: 1rem 0;
    border-radius: 10px;
  }


  .vertical-line {
    display: none;
  }

  .horizontal-line {
    display: none;

  }

  .highlight_title {
    font-size: 2.2rem;
    line-height: 1.4;
  }

  /* .diagram {
    width: 20%;
    margin-top: 2rem;
    margin-bottom: 2rem;

  } */


  .highlights-items {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem 3rem;
    height: auto;
  }

  .item1 {
    height: 10rem;
    font-size: 14px;
  }
}


/* @media screen and (max-width: 1024px) {
  .diagram {
    width: 85%;
  }

  .highlights-items {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem 3rem;
    height: auto;
  }

  .item1 {
    height: 12rem;
  }
} */




@media screen and (min-width:600px) and (max-width: 800px) {



  .diagram {
    width: 90%;
    height: auto;
  }
    .highlights-items {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1px 1px;
    }

    .horizontal-line{
      width: 96%;
    }


    .item1{
      font-size: .65rem;
      display: flex;
      margin: auto;
      flex-direction: column;
    }
    .vertical-line{
      height: 24rem;
      margin: auto;

    };
}














