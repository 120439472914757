.header2 {
  background: white;
  width: 100%;
  height: auto;
  padding: 1rem 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center;
  margin-top: -20px;
}
@media screen and (min-width: 1500px){
  .header2 {
    height: 100vh;
  }
}

.whole_content {
  display: flex;
  padding: 1rem 0;
  flex-wrap: wrap;
  width: 90%;
  margin: auto;
}



.page2-title {
  color: #000;
  font-family: Baro;
  font-size: 3rem;
  font-weight: 800;
  line-height: 1.3;
  /* 142.857% */
  text-transform: uppercase;
}


.logo2 {
  width: 370px;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-top: 20px;
}


.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding-left: 4rem;
  padding-top: 4rem;
  flex: .6;
}


.demo-btn {
  background: #0F2238;
  color: #fff;
  font-family: Baro;
  padding: 1.2rem 2rem;
  border-radius: 10px;
  margin: 20px auto;
  border: none;
}

.demo-btn:hover {
  background: #193e68;
}




.right {
  /* width: 35.6rem; */
  height: auto;
  /* margin-left: 10rem;
    margin-top: 5rem;
    margin-right:10rem; */
  flex: .4;
  display: grid;
  justify-content: end;
  place-items: center;
}



@media screen and (max-width : 1280px) {
  .whole_content .left {
    flex: .8;
  }

  .whole_content .right {
    flex: .5;
  }
}


@media (max-width: 820px) {
  .header2 {
    height: auto;
    width: 100%;
  }

  .title {
    font-size: 32px;
    line-height: 40px;

    /* margin-left: 10%; */


  }


  .page2-title {
    font-size: 1.8rem;
  }

  .left {
    text-align: center;
    padding: 0;

  }


  .right {
    width: 18rem;
    height: 18rem;
  }


  .whole_content {
    flex-direction: column-reverse;
    align-items: center;
  }

  .right {
    margin: 2rem 0;
    order: -1;
    /* Move the image above the text */
  }
}


@media screen and (max-width : 500px) {
  .page2-title {
    font-size: 1rem;
  }

  .demo-btn {
     padding: .8rem;
     font-size: .6rem;
  }
}