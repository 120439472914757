@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Poppins:wght@300&display=swap');

.interested {
  height: auto;
}

.interested-title {
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.5);
  font-family: Baro;
  font-size: 55px;
  font-style: normal;
  font-weight: 500;

  letter-spacing: 0.151px;
  text-transform: capitalize;
  padding-top: 5%;
}



.buy {
  display: flex;
  flex-wrap: wrap;
  padding-top: 5%;
  width: 100%;
  padding-bottom: 5%;
  justify-content: center;
  align-items: center;
  transform: scale(.8);
  gap: 2rem !important;
}


.offer {
  background-color: #fff;
  /* height: 23rem; */
  background: url("../../assets/offer/offer_background.png") no-repeat;
  background-size: cover;
  margin: 0;
  background-position: top;
  position: relative;
  display: flex;
  justify-content: space-around;
  padding: 1rem 0;
}

.offer_pill{
  display: flex;
  flex-wrap: wrap;
  flex: .3;
}



.offer-text {
  flex: .5;
  /* background-color: #d7d7d7; */
  display: flex;
  justify-content: center;
  align-items: start;
  text-align: left;
  flex-direction: column;
  padding: 1rem;
}

.offer-text .text1 {
  font-size: 2.5rem;
  font-weight: bold;
  font-family: Baro;
}

.offer-text .text2 {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.6;
  color: #000;
}



.pills .text {
  padding: 1rem 5rem;
  background-color: lightblue;
  border-radius: 50px;
  display: flex;
  align-items: center;
  text-align: center;
  font: 1.8rem;
  height: 3rem;
  position: sticky;
  transform: translate(-120px, 50px) rotate(-17.005deg);
  flex-shrink: 0;
  position: absolute;
  top: 60%;
  left: 30%;
  font-size: 1.6rem;
  color: white;
  border-radius: 82.742px;
  background: linear-gradient(78deg, #76439C 0%, #4489CB 100%);
  font-weight: bold;
}

@media screen and (max-width : 1280px) {
  .buy {
    gap: 3rem;
  }

  .pills .text {
    top: 45%;
    padding: 1rem 5rem;
    font-size: 1.2rem;
  }

}

@media screen and (max-width : 936px) {
  .interested-title {
    font-size: 2.5rem;
  }

  .pills .text {
    top: 30%;
    left: 40%;
    font-size: 10px;
    padding: .2rem .2rem;
    font-weight: bold !important;
    height: 25px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .offer-text .text2 {
    font-size: .8rem;
  }

  .offer {
    background-size: contain;
    height: auto;
  }
   
  .offer_pill {
     scale: .8;
  }
}


@media screen and (max-width: 770px) {

  .interested {
    height: auto;
  }

  .interested-title {
    font-size: 1.5rem;
  }

  .buy {
    gap: 2rem;
    width: 70%;
    margin: auto;
  }

  .offer {
    width: 100%;
    height: auto;
    background-position: 30%;
    /* display: flex; */
    /* flex-direction: column; */
  }



  .text1 {
    font-size: 1.5rem;
    margin-top: 0;
    left: -7rem;
    margin-bottom: 0;
  }

  .text2 {
    font-size: .6rem;
    line-height: 1.4;
    margin-right: 1.9rem;
  }

  /* .pills {
    padding: 0 1.5rem;
    font-size: .4rem;
    height: 0.9rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 18%;
    left: 48%;
    transform: translate(16px, -14px) rotate(-17px);
  } */

  .offer_pill {
    scale: .8;
 }

}

/* @media screen and (max-width: 1024px) */


@media screen and (max-width : 500px) {
  .pills .text {
    top: 8%;
    left: 50%;
    font-size: .3rem;
    padding: .1rem .8rem;
    width: 50px;
    height: 10px;
  }

  .offer-text {
    padding: 1rem 0;
  }

  .offer-text .text1 {
    font-size: .8rem;
  }

  .offer-text .text2 {
    font-size: .5rem;
    line-height: normal;
  }

  .interested-title {
    font-size: 1rem;
  }

  .offer_pill {
    scale: .7;
 }
}