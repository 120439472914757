.section1 {
  width: 100%;
  height: auto;
}

.what-codeto-title{
  width: 100%;
  text-align: center;
  margin: 2rem 0;
  color: #FFF !important;
 
}


.what-is-codeto {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 0px 40px #FFF;
    font-family: Baro;
    font-size: 60px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 1.24px;
    text-decoration-line: underline;
    text-transform: uppercase;
   
}

.codeto-box-section {
   height: 50%;
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   justify-content: center;
   gap: 2rem;
   margin-top: 1.5rem;
}

.about-codeto-box {
   width: 50%;
   height: 25rem;
   /* background-color: gray; */
   background-image: url('../../assets/rect.png');
   background-repeat: no-repeat;
   background-size: cover;
   font-family: 'Baro';
    color: #fff;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 10px;
   font-weight: 500;
}


.about-codeto-box .about {
   width: 100%;    
   line-height:2.5;
   font-size: 3rem;
   overflow: hidden;
   color: white;
}


.about-codeto-image {
  width: 25rem;
  height: 25rem;
  background: url('../../assets/rect.png');
  background-repeat: no-repeat;
  padding: .5rem;
  /* border-radius: 10px; */
  background-position: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 10px !important;
  
}

   


@media screen and (max-width : 1280px) {
   .what-codeto-title {
       padding: 1rem 0;
   }
   
   .codeto-box-section {
       width: 100% !important;
       padding: 3rem 1rem;
   }
   
   .why-codeto-box-section {
      width: 100% !important;
   }

   .why-codeto-box-section .image-box {
       height: 28rem;
   }
}


@media (max-width : 936px) {
  .section1 {
     height: auto;
     margin: 2rem 0;
  }

  .what-is-codeto{
   /* margin-top: 2rem; */
   padding : 1rem;
   font-size: 2.5rem;
  }

  .codeto-box-section {
    display: flex;
    width: 100%;
    padding: 0;
  } 

  .about-codeto-box {
   width: 70% !important;
  }

 
  .about-codeto-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about-codeto-image{
      width: 70%;
      height: auto;
      padding: 1rem 0;
      border-radius: 2rem !important;
  }
   

  .about-codeto-image img {
     scale: .89;
     /* width: 100% !important; */
  }
   
 
  .about-codeto-box {
     width: 90%;
     height: auto;
     padding: 1rem;
  }

  .why-codeto-box-section .image-box {
   height: auto;
  }
  
}

@media screen and (max-width : 500px) {
  .what-codeto-title {
     font-size: 2rem;
     margin-bottom: 1rem;
  }

  .what-is-codeto {
     margin-top: 2rem;
     padding : 1rem;
  }

  .about-codeto-box{
    width: 90% !important;
  }

  .about-codeto-image{
   width: 90%;
   height: auto;
   padding: 1rem 0;
   border-radius: 10px !important;
  }
}