.section2-technical {
  margin-top: 10rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

.teachnical-tab-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.course-grid {
  display: grid;
  border-style: none;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(3, auto);
  padding: 20px;
  grid-gap: 20px;
  justify-content: center;
  width: 90%;
  margin: auto;
  overflow: hidden;
  transition: height 2s ease;
}



.course-item {
  border: 0px;
  padding: 10px;
  width: 15rem;
  height: 15rem;
 
}

.course-item:hover{
  transform: scale(1.15);
  transition: 0.4s;
}

img {
  width: 100%;
}

.show-more-btn {
  padding: 1rem;
  border-radius: 10px;
  font-size: 21.15px;
  font-weight: 500;
  line-height: 14.89px;
  text-align: center;
  color: fff;
fill: var(--g-1, linear-gradient(90deg, #E92EFB 0%, #FF2079 100%));
backdrop-filter: blur(68.9000015258789px);
}


.show-more-btn button {
   display: flex;
   align-items: center;
   /* gap: 1rem; */
   text-transform: uppercase;
}

.showmore-line{
  color: white;
  width: 40rem;
  border: 1.7px solid;
height: 0px;
margin: 3rem;
}

@media screen and (max-width: 900px) {

  .course-grid {
    width: 90%;
    grid-gap: 0;
    grid-template-rows: repeat(2, auto);

  }

  .course-item {
    width: 10rem;
    height: 10rem;
  }

  .show-more-btn {
    gap: 2px
  }
  
}


@media screen and (max-width: 500px) {

  .course-grid {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    place-items: center;
    gap: 1.2rem;
    padding-bottom: 5rem;
  }
   
  .course-item {
     width: 12rem;
  }

  .showmore-line{
    width: 70%;
  }
}






