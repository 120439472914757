.section3 {
  width: 100%;
  margin-top: 2rem;
}

.why-codeto {
  color: #fff;
  text-align: center;
  font-family: Baro !important;
  font-size: 60px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  letter-spacing: 2.24px;
  text-decoration-line: underline;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-shadow: 0px 0px 40px #fff;
}

/* .bubble1 {
    width: 206px;
    height: 206px;
    margin-top: 20px;
    margin-left: 200px;
    justify-content: end;
  } */

  .why-codeto-box-section {
     width: 90%;
     display: flex;
     flex-wrap: wrap;
     align-items: center;
     justify-content: center;
     margin : 1.5rem auto;
     gap: 2rem;
  }


.image-box {
  border-radius: 8px;
  width: 27.4rem;
  padding: 1.5rem 1.5rem;
  background-image: url('../../assets/img-bg.png');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 100%;
  justify-content: center;
  align-content: center;
}


.why-codeto-box-1 {
   height: auto;
   background:url(../../assets/rect.png);
   background-size: cover;
   background-repeat: no-repeat;
   font-family: Baro;
   color: #fff;
   padding: 1rem;
   width: 40%;
   border-radius: 10px;
}

.why-codeto-box-1 ul {
   padding: 0;
   width: 90%;
   margin-left: 2rem;
}

.why-codeto-box-1 ul .title {
   font-size: 1.2rem;
}

ul li {
  color: fff;
   font-size: .9rem;
   margin-top: .8rem;
   margin-left: 1rem;
}

.why-codeto-box-1 ul .sub-title {
   margin-top: 1rem;
   color: fff;
}



@media screen and (max-width : 1280px) {
  .why-codeto-box-1 {
    width : 50%;   
     height: 28rem;
  }

  .why-codeto {
     margin: 2rem 0;
  }

  .why-codeto-box-section {
     margin-top: 2rem;
  }
}

@media screen and (max-width : 936px) {
  
  .why-codeto-box-1 {
     width : 70%;   
     height: 28rem;
  }
}

@media screen and (max-width : 900px) {
  .why-codeto-box-section {
     width: 90%;
     margin-top: 2rem;
  }

  .why-codeto p {
     font-size: 1.9rem;
  }

  
  .why-codeto-box-1 ul {
     width: 95%;
     padding: 0;
  }

  .why-codeto-box-1 ul li {
     font-size: .8rem;
  }

  .image-box {
    width: 100%;
    height: auto;
  }
}




@media screen and (max-width : 500px) {

  .codeto-box-section {
     width: 100% !important;
  }

  .about-codeto-box {
     width: 100%;
  }


  .about-codeto-box .about {
    font-size: .6rem;
    line-height: 1.5;
  }


  .why-codeto-box-1 {
     width: 90%;
     height: auto;
  }


  .why-codeto-box-1 ul .title {
    font-size: 1rem;
    line-height: 1.2;
    margin-left: 0;
  }
  
  .why-codeto-box-1 ul {
    padding: 0;
    margin-left: 0rem;
 }
 
 
  .why-codeto-box-1 ul li {
    font-size: .8rem;
  }
}