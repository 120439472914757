
    .navbar2 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 20px;
        font-family: Baro;
        height: 5rem;
        z-index: 10;
      }
      
      .nav2-items {
        display: flex;
        flex-direction: row;
      }
      
      .nav2-items a {
        text-decoration: none;
        color: Black;
        margin: 0 3rem;
      }
      
      .nav2-items a:active {
        text-decoration: underline;
      }
      
      .nav-button2 {
        background: #0F2238;
        border-radius: 10px;
        padding: 1rem 2rem;
        border: none;
       
        text-align: center;
        color: rgba(255, 255, 255, 1);
        margin-right: 20px;
      }
      
      .nav-button2:hover {
        background: #193e68;
      }
      
      .nav2-logo {
        width: 162px;
        height: 27px;
      }
      
    
      .hamburger-icon {
        display: none; 
      }
      
     
 
@media screen and (max-width: 992px) {
    .nav2-items {

        display: none;
        flex-direction: column;
        position: absolute;
        top: 80px;
        right: 20px;
        background-color: rgba(0, 0, 0, 0.9);
        padding: 10px;
        border-radius: 5px;
        z-index: 5;
    }
  
    .nav-items a {
      /* Adjust font size and margin for small screens */
      display: block;
      margin: 10px 0;
      text-align: center;
    }
  
    .hamburger-icon {
     
      display: block;
      cursor: pointer;
    }
  
   
    .nav-items.show {
      display: flex;
    }

    .nav-button2 {
      scale: .7;
     }
  }

  @media screen and (max-width : 500px) {
     .nav-button2 {
      scale: .7;
      padding: 10px;
     }
      
     .navbar2 {
       width: 100%;
     }
  }