.single_course {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10%;
  background-color: white;
  width: 100%;
  color: rgba(0, 0, 0, 1);
  font-family: Baro;
  padding: 3rem 0;
}


.imagecourse {
   width: 300px;
   height: auto;
}



.main_title {
  color: #000;
  font-weight: 800;

  font-size: 50px;
  font-style: normal;
  line-height: 60px;
  line-height: 60px; /* 120% */
  text-transform: capitalize;
}

.info {
  width: 585px;
  height: auto;
  display: flex;
  font-style: bold;
  font-weight: 800;
  font-size: 20px;
  line-height: 30px;
}


.duration-text{
    display: flex;
    flex-wrap: wrap;
}

.duration {
  display: flex;
  gap: 1rem;
  text-align: 20px;
  font-family: Baro;
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
}

.democlass-btn {
  background: #0f2238;
  width: 30%;
  height: 49.96px;
  border-radius: 10px;
  border: none;
  font-size: 21.15px;
  font-weight: 500;
  line-height: 14.89px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  margin-right: 20px;
  margin-top: 5em;
}

.democlass-btn:hover {
  background: #566f8f;
}

@media screen and (max-width: 820px) {
  .single_course {
    height: auto;
    justify-content: center;
    align-items: center;
  }

  .main_title {
    width: 100%;
    text-align: center;
    font-size: 1.8rem;
  }

  .info {
    font-size: 0.9rem;
    width: 90%;
    text-align: center;
    /* margin: 0 2rem; */
    margin : auto;
    height: auto;
  }
  
  .duration{
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

  }

  .duration-text {
    font-size: 0.9rem;
    justify-content: center;
  }

  .imagecourse {
    width: 40%;
    height: auto;
    padding: 0.5rem;
  }
 
  .democlass-btn{
    /* margin: 2rem; */
    font-size: .8rem ;
    width: 10rem;
    height: auto;
    padding: 0.7rem 2rem;
    /* justify-content: center; */
    /* align-items: center; */
    /* margin-left: 36%; */
  }
  
  .right-side {
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
  }
}

