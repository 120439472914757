
.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    /* margin: 16px; */
    padding: 2rem 1rem;
    font-family: Baro;
    height: 5rem;

  }

  @media screen and (min-width: 1500px) {
    .navbar {
      margin: 16px;
    }
  }
  
  .nav-items {
    display: flex;
    flex-direction: row;
  }
  .nav-items, .nav-logo,  .tagline-logo .nav-button  {
    /* Your existing styles */
    animation: slideIn 1s ease-in-out,  dashOffset 2s linear infinite;;
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-70px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .nav-items a {
    text-decoration: none;
    color: white;
    margin: 0 3rem;
  }
  
  .nav-items a:hover{

    color: rgb(163, 159, 159);

  }

  .nav-link.active {
    color: rgb(228, 64, 166);
    
  }

  
  .nav-button {
    background: linear-gradient(90deg, #e92efb 0%, #ff2079 100%);
    padding: 1rem;
    border-radius: 10px;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    line-height: 14.89px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    /* margin-right: 20px; */
    gap: .5rem;
  }
  
  .nav-button:hover {
    background: linear-gradient(90deg, #4f0c54 0%, #c91b61 100%);
  }
  
  .nav-logo {
    width: 162px;
    height: 27px;

  }
  
  /* Styles for hamburger icon */
  .hamburger-icon {
    display: none; /* Hide the icon by default on larger screens */
  }

  .tagline-logo{
    color: #ffffffe4;
    font-size: 8px;
    padding-top: .5rem;
    font-weight: 100;
    
    
  }

  /* Navbar.css (your existing CSS) */

/* ... (your existing styles) */

/* Styles for the dropdown menu */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: white;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #0b0101;
  width: 500px;
  height: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.311);
  z-index: 1;
  display: flex;
  border-radius: 10px;
  padding: 1.2rem 0;
}

.dropdown-content a {
  color: #f8f4f4;
  /* padding: 9px 12px; */
  text-decoration: none;
  display: block;
  font-size: 12px;
  transition: background-color 0.3s;
  position: relative;
  /* margin: 16px 20px; */
  padding: .5rem;
  border-radius: 10px;
  
}

/* .dropdown-content a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  left: 0;
  bottom: 0;
  
  transition: all .5s linear;
} */


/* .dropdown-content a:hover::after {
  width: 100%;
} */
.dropdown-content a:hover {
  /* background-color: #454141; */
  background: linear-gradient(90deg, hsla(221, 45%, 73%, 1) 0%, hsla(220, 78%, 29%, 1) 100%);
  color: #fff;
  
}

.dropdown:hover .dropdown-content {
  display: flex;
}


  
  
  /* Media query for smaller screens */
  @media (max-width: 910px) {
    .nav-items {
      display: none; 
      flex-direction: column;
      position: absolute;
      top: 80px;
      right: 20px;
      background-color: rgba(0, 0, 0, 0.9);
      padding: 10px;
      border-radius: 5px;
    }
  
    .nav-items a {
      display: block;
      margin: 10px 0;
      text-align: center;
    }
  
    .hamburger-icon {
      display: block;
      cursor: pointer;
      z-index: 10;
    }
  }
  
  @media screen and (max-width : 936px) {
     .nav-buttons {
       display: flex;
       gap: 2rem;
       align-items: center;
     }
  }

/* Add media query for mobile screens */
@media screen and (max-width: 768px) {
  .mobile-dropdown {
    display: block;
    margin-top: 15px;
  }

  .mobile-dropdown .dropdown-content {
    position: static;
    display: block;
    background-color: transparent;
    box-shadow: none;
    border: none;
  }
}


  @media screen and (max-width : 500px) {
     .navbar {
       display: flex;
       flex-direction: row;
       justify-content: space-around;
       width : 100%;
       padding: 2rem 0;
     }
     
     .nav-button {
       padding: 10px;
       font-size: 10px;
     }
     .nav-buttons {
       gap: .5rem;
     }
  }