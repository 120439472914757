.chatbot-logo-container {
  position: fixed;
  bottom: 20px;
  right:3%;
  color: white;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: rgba(0, 194, 255, 0.56);
  transition: transform 0.3s ease-in-out ;
  z-index: 3;
  
}

.chat-bot-logo:hover{
  transform: scale(1.08);
}


.chat-close{
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: white;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: rgba(0, 194, 255, 0.56);
  border: 1px solid rgba(0, 194, 255, 0.56);;
  transition: transform 0.3s ease-in-out ;
}



@media screen and (max-width: 500px) {

  .chatbot-logo-container{
    right:7%;
    bottom: 3rem;
   
  }
  
}


