.chatbot-container {
  position: fixed;
  bottom: 40px;
  right: 2.5%;
  width: 300px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: auto;
 z-index: 1;
 transform: scale(.8);
}



.chatbot-messages {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
}

.message {
  margin: 8px;
  padding: 8px;
  border-radius: 4px;
}

.user {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
}

.bot {
  background-color:#1288BE;
  color: white;
  align-self: flex-start;
}

.jpJyWr {
background-color: #FEE28F;
margin: auto;
}
 
@media screen and (max-width:700px) {

  .chatbot-container{
    bottom: 75px;
    right: 10%;
    transform: scale(1);
    }

}


.loader {

  display: block;
  margin: 20px auto;
  position: relative;
  box-sizing: border-box;
  animation: rotationBack 1s ease-in-out ;
}

.loader::before {
  content: '';
  box-sizing: border-box;
  left: 0;
  top: 0;
  transform: rotate(45deg);
  position: absolute;
  width: 48px;
  height: 48px;
  background: #2e2e2e;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.loader::after {
  content: '';
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  background: rgb(0, 0, 0);
  transform: translate(-50%, -50%);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}


