.roadmap {
    background: white;
    width: 100%;
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
}

.roadmap-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  scale: .8;

}

.roadmap-scale {
     width: auto;
}

.roadmap-title {
    color: #000;
    text-align: center;
    font-family: Baro;
    font-size: 60.053px;
    font-weight: 600;
    line-height: 25.623px;
    /* 42.667% */
    padding-top: 7%;
}

.polygon {
    padding-top: 1rem;
    margin-top: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -3.4rem;
    color: #000;
    font-family: Baro;
    font-size: 17.616px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.polygon div {
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;
}


.polygon div p {
    margin: 0;
    height: auto;
    text-align: left;
    transform: translate(-10px);
    font-size: .9rem;
}


@media screen and (max-width : 425px) {
    .roadmap-scale {
         width: 80% !important;
         margin-left: auto;
    }
}



@media screen and (max-width: 800px) {
    .roadmap {
        height: auto;
    }
    

    .roadmap>.polygon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        gap: 2rem;
        padding: 2rem 0;
    }

    .polygon div {
        display: flex;
        flex-direction: column;
        align-items: left;
        /* margin: 1rem 0; */
    }

    .polygon div p {
        margin: 0;
        height: auto;
        font-size: .9rem;
        text-align: left;
        line-height: 1.4;
    }

    .roadmap-title {
        font-size: 2rem;
    }

}



@media screen and (max-width:1100px) {
    
    .polygon {
        padding-top: 1rem;
        margin-top: 5%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: -3.4rem;
        color: #000;
        font-family: Baro;
        font-size: 17.616px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
       
    }
    
    .roadmap {
        width: 100%;
        height: auto;
    }

    .roadmap>.polygon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        gap: 2rem;
        padding: 2rem 0;
    }
    


}


@media screen and (max-width:1707px) {
    .polygon{
        width: 100%;
    }
}

