/* Sidebar.css */
.sidebar {
  position: fixed;
  top: 0%;
  left: -250px;
  width: 250px;
  height: 100%;
  /* overflow-y:hidden ; */
  background-color: #111111e9;
  backdrop-filter: blur(10px);
  padding-top: 60px;
  transition: left 0.3s ease-in-out;
  z-index: 5;
}

.sidebar.open {
  left: 0;
}

.sidebar a {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 10px 20px;
  font-size: 16px;
  transition: background-color 0.3s;
}

.sidebar a:hover {
  background-color: #444;
}


/* Disable scrolling when sidebar is open */
body.sidebar-open {
  overflow: hidden;
}
