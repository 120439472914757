.dark-theme .modal-content {
  background-color: #111112d0;
  color: #ffffff;
  border: .5px solid rgb(59, 59, 59);

}

.dark-theme .modal-header {
  border-bottom: none;
  color: white;
  font-size: bold;
}

.dark-theme .modal-title {
  color: #f9ecec;
  font-size: small;
}

.dark-theme .modal-body {
  background-color:  #111112d0;
}

.dark-theme .modal-footer {
  border-top: 1px solid #111112d0;
}

.dark-theme .form-label {
  color: #ffffff;
}

.dark-theme .form-control {
 
  color: #1c1717;
}

.dark-theme .text-danger {
  color: #dc3545;
}


.dark-theme button{
  color: #ffffff;
}



.formbtn{
  color: #3b3b3b;
  border: 1px solid rgb(131, 128, 128);
}


.modallogo{
  width: 9rem;
  height: 1.5rem;
}




