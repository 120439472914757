.courses {
  width: 100%;
  /* height: 100vh; */
  margin-top: 4rem;
  margin-bottom: 6rem;
}

.courses-head {
  color: #fff;
  text-transform: capitalize;
  text-transform: uppercase;
  /* margin-top: 1%; */
}

.main-title {
  text-align: center;
  text-shadow: 0px 0px 40px #fff;
  font-family: Baro;
  font-size: 60px;
  font-style: normal;
  font-weight: 200;
  /* line-height: normal; */
  letter-spacing: 3px;
  text-decoration-line: underline;
}

.child-title {
  text-align: center;
  font-family: Baro;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 137.9%; */
  /* 27.58px */
  letter-spacing: 6.4px;
  margin-top: 1.2rem;
}


/* tabs section */

.tab-conatiner {
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  font-family: Baro;
  color: #fff;
  justify-content: center;
  align-items: center;
  

}

.formTab {
  color: #fff;
  border-radius: 6.117px;
  justify-content: space-around;
  background: rgba(209, 143, 228, 0.24);

  box-shadow: 0px 3.058631181716919px 3.058631181716919px 0px rgba(0, 0, 0, 0.25),
    0px 3.058631181716919px 3.058631181716919px 0px rgba(255, 255, 255, 0.25) inset;
  width: 40%;
  height: 80px;
  margin: auto;
  padding: 1rem;


}

.formTab li {
  margin: 0.25rem 0;
  width: 30%;
}

.formTab li button {
  color: #fff;
  min-width: 150px;
  border-radius: 6.117px;
  background: #3e3e3e;

  box-shadow: 0px 3.058631181716919px 3.058631181716919px 0px rgba(0, 0, 0, 0.25),
    0px 3.058631181716919px 3.058631181716919px 0px rgba(255, 255, 255, 0.25) inset;

  font-family: baro;
}

.formTab li button:hover {
  background: #242424;
  color: white;
  border: none;
  outline: none;
  border-radius: 10px;
}

.nav-tabs .nav-link.active {
  border: none;
  outline: none;
  border-radius: 6px;
  color: white;
  background: linear-gradient(180deg, #b822ff 0%, #230033 100%);
}

.nav-fill .nav-item {
  flex: 0 0 auto;
  border-radius: 10px;
}

.tab-content {
  flex: 0.8;
}


.mb-3 {
  margin-top: 3%;
}

@media screen and (max-width : 1280px) {
  .courses {
     margin-top: 3rem;
  }
}

@media screen and (max-width : 820px) {
  .courses-head {
    margin-top: 4rem;
  }

  .main-title {
    font-size: 3rem;
  }

  .child-title {
    font-size: 1rem;
    padding: 1rem;
  }

  .formTab {
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: .5rem;
    margin-top: 2rem;
  }

  .formTab li {
    width: 30%;
  }
}

@media screen and (max-width : 500px) {
  .courses-head {
    margin-top: 2rem;
  }

  .main-title {
    font-size: 2rem;
  }

  .child-title {
    font-size: .8rem;
    margin-top: 2rem;
  }

  .formTab {
    width: 99%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin-top: 2rem;

  }
  
 
  .formTab .nav-item{
     flex :1;
  }

  
}