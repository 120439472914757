@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Play&family=Poppins:wght@300&display=swap');

.frequently-question-title {
  color:  #fff;
  text-align: center;
  text-shadow: 0px 0px 17px #fff;
  font-family: Baro;
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
  line-height: 49px;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
  text-transform: capitalize;
  margin: 4rem 0 2rem 0 ;
}


.accordion-box {
  width: 85%; 
  max-width: 70rem; 
  margin: 0 auto;

}


.accordion-item {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.24); 
  font-family: Play;
  font-weight: 200;
  color: #fff; 
  background: transparent;
  margin: 1rem 0;
}


.accordion-header {
  background: rgba(222, 26, 26, 0);
}


.show-more{
 padding: auto;
  border-radius: 10px;
  font-size: 21.15px;
  font-weight: 500;
  line-height: 14.89px;
  text-align: center;
  fill: var(--g-1, linear-gradient(90deg, #E92EFB 0%, #FF2079 100%));
  margin: 20px auto; 
  text-transform: uppercase;
}


.accordion-button {
  color: #fff;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.041);
 
  
}


.accordion-collapse {
  color: #fff;
}

/* .accordion-button::after{
  background-color: #fff;
  border-radius: 50%;
  
} */
.accordion-button::after {
  background-image: url('../../assets/accordin_arrow.png') !important;
}


@media screen and (max-width: 600px) {
  .accordion {
     margin-top: 0;
  }
  .frequently-question-title {
    font-size:1.2rem;
    width: auto;
    margin-top: 2rem;
  }

  .accordion-box {
    width: 90%;
  }

  .show-more {
    font-size: 18px;
  }
}




@media screen and (max-width: 936px) {
  .accordion {
     margin-top: 0;
  }
  .frequently-question-title {
    font-size:1.2rem;
    width: auto;
    margin-top: 2rem;
  }

  .accordion-box {
    width: 90%;
  }

  .show-more {
    font-size: 18px;
  }
}


@media screen and (max-width: 500px) {
  .frequently-question-title {
    font-size: 1rem;
  }
}
