.privacy{
    color: aliceblue;
    padding: 2rem;
    height: auto;
    width: 100%;
    font-family: Play;
}
.privacy-title{
    color: aliceblue;
    text-align: center;
}
.privacy p{
    padding: 2rem;
    font-size: 1rem;
    color: lightgray;
    opacity : .8;
}
.privacy h4{
    color: #fff;
    font-weight : 600;
}

@media screen and (max-width : 500px) {

    .privacy p{
        padding: .1rem;
        font-size: medium;
        
    } 
    
}