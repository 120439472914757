
.header {
  background: url(../../assets/mainBg.png) ;
  width: 100%;
  height: auto;
  /* height: 100vh; */
  background-position: top; 
  background-size: cover;
  background-repeat: no-repeat;
  align-items: center; 
  margin-top: -20px;
  
}
@media screen and (min-width: 1500px){
  .header {
    height: 100vh;
  }
}

/* .header::after {
  content:  "div";
  background-color: #000000;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: .3;
  z-index: 1;
} */

.section1 {
  width: 100%;
  height: 100vh;
}

/* Styles for the header title */
.header-title {
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 40px 0px #000;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 50px;
  font-weight: 900;
  line-height: 81px;
  letter-spacing: 3.5px;
  text-transform: capitalize;
  margin-top: 10%; 
  /* position: relative;
  z-index: 2; */
}


.logo2 {
  width: 370px; 
  height: auto; 
  display: block; 
  margin: 0 auto; 
  margin-top: 20px; 
  /* position: relative;
  z-index: 2; */
}


.shawdow{

  width: 100%;
  height: 100px;
  flex-shrink: 0;
  background: #00000084;
  filter: blur(50px);
  z-index: -100;
 
}

.registernow {
  background: linear-gradient(90deg, #e92efb 0%, #ff2079 100%);
  color: #fff ;
  font-family: Baro;
  /* backdrop-filter: blur(68.9px); */
  padding: .7rem 1rem;
  border-radius: 10px;
  margin: 20px auto;
  border: none;
  /* opacity: 0.70; */
  box-shadow: 0px 0px 30px 15px rgb(165, 163, 163);
  margin-bottom: 50px; 
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.registernow:hover {
  background: linear-gradient(90deg, #ae21bb 0%, #ad1552 100%);
}

@media screen and (max-width : 1280px) {
  .section1 {
    height : auto;
  }
}

@media screen and (max-width : 936px) {
  
}


@media (max-width: 768px) {
  .header-title {
    font-size: 40px;
    line-height: 60px;
    margin-top: 22%; 
  }

  .logo2 {
    width: 150px;
    height: auto;
    margin-top: 30px; 
  }

  .registernow {
    width: auto;
    height: 42px;
    font-size: 15px;
    margin-bottom: 30px;
  }
}


@media screen and (max-width: 500px) {
  .header {
     height: 100vh;
  }



  .header-title {
     font-size: 2rem;
     line-height: 40px;
    
  }
}



@media screen and (max-width: 1200px){
  .shawdow{
    display: none;
  }
}




.header-title,.logo2 , .logo {
  /* Your existing styles */
  animation: slideIn 1s ease-in-out,  dashOffset 2s linear infinite;;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-70px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}





.bubble {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: inset 0 0 25px rgba (255, 255, 255, 0.25);
  animation: animate_4010 8s ease-in-out infinite;
}

.bubble:nth-child(2) {
  position: relative;
  zoom: 0.45;
  left: -10px;
  top: -100px;
  animation-delay: -4s;
}

.bubble:nth-child(3) {
  position: relative;
  zoom: 0.45;
  right: -80px;
  top: -300px;
  animation-delay: -6s;
}

.bubble:nth-child(4) {
  position: relative;
  zoom: 0.35;
  left: -120px;
  bottom: -200px;
  animation-delay: -3s;
}

.bubble:nth-child(5) {
  position: relative;
  zoom: 0.5;
  left: 0px;
  top: 200px;
  animation-delay: -5s;
}

@keyframes animate_4010 {
  0%,100% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(20px);
  }
}

.bubble::before {
  content: '';
  position: absolute;
  top: 50px;
  left: 45px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  /* background: #fff; */
  z-index: 10;
  filter: blur(2px);
}

.bubble::after {
  content: '';
  position: absolute;
  top: 80px;
  left: 80px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  /* background: #fff; */
  z-index: 10;
  filter: blur(2px);
}

.bubble span img{
  position: absolute;
  border-radius: 50%;
}

.bubble span:nth-child(1) {
  inset: 10px;
  border-left: 15px solid #6d0c2e;
  filter: blur(8px);
}

.bubble span:nth-child(2) {
  inset: 10px;
  border-right: 15px solid linear-gradient(90deg, #e92efb 0%, #ff2079 100%);;
  filter: blur(8px);
}

.bubble span:nth-child(3) {
  inset: 10px;
  border-top: 15px solid linear-gradient(90deg, #e92efb 0%, #ff2079 100%);;
  filter: blur(8px);
}

.bubble span:nth-child(4) {
  inset: 30px;
  border-left: 15px solid #ff4484;
  filter: blur(12px);
}

.bubble span:nth-child(5) {
  inset: 10px;
  /* border-bottom: 10px solid #fffcfc; */
  filter: blur(8px);
  transform: rotate(330deg);
}


@media screen and (max-width: 700px) {
  .bubble-container{
    display: none;
  }
}