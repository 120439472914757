.footer {
  background: rgb(32, 32, 32);
  color: #fff;
  border-top: blur(180px);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
    z-index: 2;
    
}

.footer-logo img {
  max-width: 40rem;
  padding-bottom: 1rem;
}

.footer-text {
  font-weight: 600;
  text-align: center;
}

.social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
}

.social-media div {
  background-color: #d1cccc;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  border-radius: 50%;
}


.full{
  display: flex;
  justify-content: space-evenly;
  gap: 10rem;
}

.social-media,
.contact-section {
   flex-grow: 1;
   flex-shrink: 0;
}

.social-media img{
  width: 1.5rem;
  height: 1.5rem;
}


.contact-section img{
  width: 1.5rem;
  height: 1.5rem;
}


.social-media div:hover {
  background-color: #585858;
}


.social-media i,
.contact-section i {
  font-size: 20px;
}

.contact-section {
   display: flex;
   flex-direction: column;
   width: 60%;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   padding: 1rem;

}

.footericonStyle  {
   background-color: rgba(211, 211, 211, 0.34);
   border: none;
   width: 40px;
   height: 40px;
   text-align: center;
}



a {
  color: white;
}

.footer-end {
  color: rgba(255, 255, 255, 1);
  font-family: Play;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  display: flex;
  margin-bottom: -1rem;
  flex-direction: end;  
  
}

.mobile,
.address,
.weblink{
  display: flex;
  width : 100%;
  margin: .4rem 0;
}

.privacy-footer{
  text-decoration: none;
}

.privacy-footer :hover{

color: rgb(105, 105, 255);
}


.terms-footer :hover{

  color: rgb(105, 105, 255);
  }


@media screen and (max-width : 1024px) {
  .full {
    flex-wrap: wrap;
    gap: 0px;
  }
  .mobile,
.address,
.weblink {
   justify-content: left;
}

@media screen and (max-width : 475px) {
  .mobile,
  .address,
  .weblink {
     justify-content:unset;
  }


 
}
   /* .contact-section {
      width: 90%;
      flex-wrap: nowrap;
   } */

   /* .contact-section div {
      width: 33.3%;
      justify-content: flex-start;
   } */
}

/* @media screen and (max-width : 936px) {
  .contact-section {
     width: 100%;
     flex-direction: column;
     justify-content: center;
     align-items: start;
     gap: 1rem;
  }

  .contact-section div {
    width: auto;
    justify-content: flex-start;
 }
} */


/* @media screen and (max-width: 500px) {
  .contact-section {
    display: flex;
    flex-direction: column;
    width: auto;
    justify-content: left;
    text-align: left;
    align-items: self-start;
   
  }

  .contact-section div{
    padding: 1rem;
    width: auto;
  }

  

} */