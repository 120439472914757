.section2-designing{
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
/* 
.uiux:hover{
 background-color: aliceblue;
} */

.tab-desinging-content{
  scale: .8 ;
}
.tab-desinging-content:hover{
  transform: scale(1.1);
  transition: 0.4s;
}


.showmore-line2{
  
    color: white;
    width: 15rem;
    border: 1.7px solid;
    height: 0px;
  }


@media screen and (max-width: 500px) {

    .section2-designing {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .tab-desinging-content {
         width: 12rem;
    }  

    .showmore-line2{
  
      color: white;
      width: 10rem;
      border: 1.7px solid;
      height: 0px;
    
    }
  }

  

@media screen and (min-width : 600px) {
  .showmore-line2 {
     width: 30rem !important;
  }
}
