.admin-container {
   height: 100vh scroll;
   width: 100%;
   background-position: scroll;
   background-attachment: scroll;
   /* background: url('../../assets/userlistbg.png') ; */
}

.table{
    width: 95%;
    height: auto;
    margin: 7rem 2rem;
    font-size: .8rem; 
    
    /* overflow: scroll; */
}


.logout{
    color: aliceblue;
}

.logout:hover{
    color: rgb(110, 111, 111);
}

select {
    padding: 5px;
    color: white;
    background-color: rgb(31, 36, 88);
    border: 1px solid #ece1e1;
    border-radius: 3px;
    font-size: 14px;
  }

 
  @media screen and (max-width:400px) {
    .adminname{
      display: none !important;
    
    }
  }
 

