.swiper {
    width: 100% !important;
    height: 45rem !important;
}

.imgtwl{
    border-radius: 50%;
}


.swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex !important;
    padding: 1rem;
    justify-content: center !important;
    align-items: center;
    gap: 1rem;
}

.swiper-slide>.box:nth-child(even) {
    scale: 1.1;
    transform: translateY(-15px);
}


.swiper-wrapper {
    width: 100% !important;
    margin: auto;
    /* transform: translateY(-50px) !important; */
}

.box {
    background-color: rgb(240, 240, 240);
    width: 300px !important;
    margin: 0 .5rem;
    border-radius: 0px !important;
}

.box-content p{
    font-weight: bold;
}

.st-name{
    font-size: 15px;
    font-size: 800;
    font-weight: 900;
  
}


.box-image {
    position: absolute;
    top : -20%;
}


.ach-box{
    border-radius: 1.2rem !important;
}
.box-content {
     display: flex;
     font-size: small;
     padding: 2rem;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     height: 200px;
     gap: 1rem;
}


.swiper-pagination {
    height: 10% !important;
    transform: translateY(200px);
}

.swiper-button-prev {
    transform: translateX(-5px);
}

.swiper-button-prev::after,
.swiper-button-next::after
 {
     font-size: 2.8rem !important;
     color: #fff;
}

.desktop-Swipper {
     display: none !important;
}

@media screen and (min-width : 425px) {
     
    .desktop-Swipper {
        display: none !important;
        height: auto;
   }

   .mobile-Swipper {
        display: block !important;
   }
    
    .achivement-container .swiper {
        height: auto !important;
    }

    .swiper {
        height: 40rem;
        width : 60%
    }
}



@media screen and (min-width : 768px) {
    
   
    .achivement-container .swiper {
         height: auto !important;
    }

    .swiper {
        height: 35rem;
        width : 60%
    }


}
 

 
@media screen and (min-width : 1024px) {
    .desktop-Swipper {
        display: block !important;
        height: auto;
   }

   .mobile-Swipper {
        display: none !important;
   }

}