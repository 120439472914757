.rating-title {
  color: #fff;
  padding-top: 1rem;
  text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.5);
  font-family: Baro;
  font-size: 2.9rem;
  font-style: normal;
  font-weight: 800 !important;
  letter-spacing: 0.151px;
  text-transform: capitalize;
  text-align: center;
  /* transform: translateY(100px); */
}

.carousel-control-prev {
  left: -42px !important;
  color: aliceblue !important;
}

.carousel-control-next {
  right: -40px !important;
}
.carousel-indicators{
  transform: translateY(60px)
}
.carousel-indicators button {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
  background: white;
}
[data-bs-theme=dark].carousel .carousel-indicators [data-bs-target]{
   background-color: white;
}

.items {
  width: 80%;
  height: 100%;
  height: 320px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  background: rgba( 255, 255, 255, 0.1 );
backdrop-filter: blur( 9px );
-webkit-backdrop-filter: blur( 9px );
border-radius: 10px;
border: 1px solid rgba(255, 255, 255, 0.548);
  margin: auto;
}

.student-image {
  width: 250px;
  height: 250px;
  border-radius: 50% !important;

}

.content {
  flex: 0.6;
  color: #fff;
  display : flex;
  flex-direction : column;
}

.stars {
    justify-content : start;
}
/* .carousel-control-prev,
.carousel-control-next {
  color: #fff;
  background-color: rgba(242, 247, 247, 0.107);
} */

.carousel-control-prev-icon {
   background-image : url(../../public/arrowsvg.svg)
}

.carousel-control-next-icon {
   background-image : url(../../public/arrowRight.svg);
}


@media screen and (max-width: 768px) {
  .carousel {
    width: 90% !important;
  }

  .carousel-control-prev {
    left: -55px !important;
  }

  .carousel-control-next {
    right: -55px !important;
  }

  .content p {
   font-size: 10px !important;
 
 }
}

@media screen and (max-width: 480px) {
  .items {
    flex-direction: column;
    padding: 1rem;
    height: 450px !important;
    width: 100%;
    border: .5px solid rgba(255, 255, 255, 0.548);
  }


  .content {
    text-align: center;
    align-items:center;
  } 
  .stars {
   justify-content : center;
}

  .content p {
    font-size: 12px !important;
    width : 80%;
    text-align:center;
  }

  .carousel {
    width: 80% !important;
  }

  .carousel-control-prev {
    left: -40px !important;
  }

  .carousel-control-next {
    right: -40px !important;
  }
  .student-image{
   width: 150px;
   height: 150px;
  }
}
