.features-title{
    display: flex;
    justify-content: center;
color: #FFF;
text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.50);
font-family: Baro;
font-size: 75px;
font-style: normal;
font-weight: 600;
line-height: 35.01px; /* 46.68% */
letter-spacing: 0.150px;
text-transform: uppercase;
margin-top: 5%;
}


.features-content{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 6rem;
    margin-bottom: 3rem;
    gap:10%
    
}

.features-left,
.features-right{
    display: flex;
    flex-direction: column;
    
}
.fimg{
    display: flex;
    flex-direction: column;
}


.fimg4{
    width: 303.943px;
height: 284.209px;

}

.fig4-container{
    display: flex;
    margin: auto;
}



 .fimg-block2{
display: flex;
flex-direction: column;
justify-content: center;
} 

.ftext{
    color: #FFF;
text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.50);
font-family: Baro;
font-size: 32px;
font-style: normal;
font-weight: 500;
line-height: 39.4px; /* 123.125% */
letter-spacing: 0.161px;
text-decoration-line: underline;
text-transform: capitalize;
margin-top: 3rem;
display: flex;
justify-content: center;
}


@media screen and (max-width: 820px) {

    .features-title{
        font-size: 2.2rem;
    }

    .features-right{
        margin-top: 4rem;
    }
    

    .ftext{
        font-size: 1.5rem;
    }

    .features-content {
         gap: 0rem;
         margin-top: 1rem;
    }

    .fimg {
        scale: 1;
    }
}


@media screen and (max-width: 600px) {

    .features-title{
        font-size: 2.2rem;
    }

    .features-right{
        margin-top: 4rem;
    }
    

    .ftext{
        font-size: 1.5rem;
    }

    .features-content {
         gap: 0rem;
         margin-top: 1rem;
    }

    .fimg {
        scale: .8;
    }
}

