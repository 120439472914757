.terms{
    color: rgb(216, 222, 227);
    padding: 2rem;
    height: auto;
    width: 100%;
    font-family: Play;
}

.terms-title{
    color: rgb(202, 207, 211);
    text-align: center;
}
.terms p{
    padding: 2rem;
    font-size: 1rem;
    color: lightgray;
    opacity : .8;
}

@media screen and (max-width : 500px) {

    .terms p{
        padding: 0rem;
        font-size: 12px;
    } 
    
}