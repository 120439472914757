    .achievements-title {
        color: #FFF;
        text-align: center;
        text-shadow: 0px 0px 40px #FFF;
        font-family: Baro;
        font-size: 64px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 2.24px;
        text-decoration-line: underline;
        text-transform: uppercase;
        margin-top: 6%
    }



    .boxes {
        display: flex;
        padding: 1.2rem;
        background-color: #fff;
        height: 18rem;
        align-items: center;
        width: 18rem !important;
        border-radius: 10rem;
        box-shadow: 0px 0px 50px 0px rgba(255, 255, 255, 0.50);
        position: relative;
        left: 5rem;
     
    }


    .boxes-big {
        display: flex;
        padding: 1.2rem;
        background-color: #fff;
        height: 18rem;
        align-items: center;
        width: 18rem !important;
        box-shadow: 0px 0px 50px 0px rgba(255, 255, 255, 0.50);
        position: relative;
        left: 5rem;
    }



    .ach-img {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 6rem;
        margin: auto;


    }

    .score {
        color: #000;
        font-family: Baro;
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: 31.482px;
        /* 98.38% */
        letter-spacing: 0.128px;
        text-transform: uppercase;
        text-align: center;
    }



    .bottom-border {
       
        width: 100%;
        height: 5%;
        border: 5px solid white;
        background-color: #FFF;

    }


    .tag {
        color: #000;
        text-align: center;
        font-family: Baro;
        font-size: 16.804px;
        font-style: normal;
        font-weight: 800;
        line-height: 23.437px;
        /* 139.472% */
        letter-spacing: 0.128px;
        text-transform: capitalize;
        text-align: center;
        margin-top: 1.5rem;
    }


    @media screen and (max-width: 600px) {


        .achievements-title {
            font-size: 1.5rem;
        }

        .bottom-border {
            height: 5%;

        }

        .boxes {
            height: auto;
            width: 100% !important;
            height: 20rem;

        }

        .boxes-big {
            width: 100% !important;
            height: auto;
        }

        .tag {
            width: 100%;
            height: 5rem;
        }

    }

    @media screen and (max-width : 1280px) {
        
    }


    @media screen and (max-width: 500px) {


        .achievements-title {
            font-size: 2.5rem;
        }

        .bottom-border {
            height: 5%;
        }


        .slick-slider {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            height: auto;
            margin-top: 2rem;
        }


        .boxes {
            height: auto;
            width: 100% !important;
            height: 18  rem;
            border-radius: 1rem;
        }

        .boxes-big {
            width: 100% !important;
            height: auto;
        }

        .tag {
            width: 100%;
            height: 5rem;
        }

    }