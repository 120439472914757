.support {
    background: white;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    margin-top: 4%;

}

.support-title {
    display: flex;
    justify-content: center;
    color: #000;
    font-family: Baro;
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: 92.259%;
    letter-spacing: 0.188px;
    text-transform: uppercase;
    padding-top: 5%;
}

.support-content {
    margin-top: 5em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20rem;
}

.support-head {
    padding-top: 2rem;
    color: #000;
    text-align: center;
    font-family: Baro;
    font-size: 40px;
    font-weight: 800;
    line-height: 46.129px;
    /* 115.324% */
    letter-spacing: 0.188px;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    gap: 5rem;

}


.support-about {
    color: #1f1e1e;
    text-align: center;
    font-family: Baro;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 36.129px;
    /* 177.421% */
    letter-spacing: 0.188px;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    width: 100%;

}

.support-left-side,
.support-right-side {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width : 820px) {
    .support-title {
        width: 100%;
        text-align: center;
        font-size: 1.5rem;
    }

    .support {
        height: auto;
    }

    .support-head {
        font-size: 2rem;
    }

    .support-content {
        gap: 2rem;
    }

    .support-text-left,
    .support-text-right {
        font-size: 1.2rem;
    }


    .support-about {
        font-size: 1rem;
        line-height: 1.5;

    }


}